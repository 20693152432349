import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:20px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 400;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`

function download(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
}

export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState("all");
    const [eventValue, setEventValue] = useState("");
    const [exported, setExported] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(3);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);
    const [type,setType] = useState("allusers");
    const [eventEnabled,setEventEnabled] = useState(false);
    const [exportTypesArray,setExportTypesArray] = useState([]);
    const exportTypes = {
        "allusers":{
            events:false,
            name:"All users",
        },
        "mailableusers":{
            events:false,
            name:"Mailable users",
        },
        "alluserevent":{
            events:true,
            name:"All users on specific event",
        },
        "mailableuserevent":{
            events:true,
            name:"Mailable users on specific event",
        },
        "reward winners":{
            events:true,
            name:"Reward winners",
        },
        "eventdata":{
            events:true,
            name:"All event user data",
        }
        
    }


    function changed(e){
        setValue(e.target.value);
        if (exportTypes[e.target.value]) {
            if (exportTypes[e.target.value].events) {
                setEventEnabled(true);
            }
            else {
                setEventEnabled(false);
            }
        }
        else {
            eventEnabled(false);
        }
        checkActive();
    }
    
    function changedEvent(e){
        console.log("changed event",e.target.value)
        setEventValue(e.target.value);
    }

    function importaschanged(e){
        setimportasValue(e.target.value);
        console.log(e.target.value);
    }

    async function getFeed(){
        let data = await GetList("events",0,9999999,"event_start asc","",{},-1);
        let optionarray = [];
    
        for (let i =0;i<data.records.data.length;i++) {
            let section = data.records.data[i];
            optionarray.push({key:section.event_id,value:section.event_name});
        }
        setOptions(optionarray);
        setEventValue(optionarray[0].key);
    }

    function checkActive(){
        console.log("checkactive ",value,exported);
    }

    async function submit(){
        setExported(false)
        let resultdata = "";
        setExporting(true);
        let body = {
            "type":"export",
            "CMSUser-Token":localStorage.getItem('token'),
            "CMSUser-Agent":navigator.userAgent,
            "subtype":value,
            "state":importasvalue,
            "eventid":eventValue,
        }
    
        await axios({
            method: "post",
            url: mainjson().export_path,
            data: body,
          })
        .then((response) => {
           if (response.data) {
               resultdata = response.data;
               download("export.csv",resultdata.csvdata);
               setExported(true);
               setExporting(false);
           }
        })
        .catch((error) => {
            console.log("error "+error);
        });
    
        return resultdata;
    }

    function  makeArray(){
        let exportTypesTemp = exportTypesArray;
        for (const key in exportTypes) {
            let types = exportTypes[key];
            exportTypesTemp.push({key:key,value:types['name']});
           
        }
        setExportTypesArray(exportTypesTemp);
    }

    useEffect(() => {
        getFeed();
        makeArray();
    }, []);    

  return (
    <Layout>
        <Header>
            <Title>
                    Export CSV
            </Title>

        </Header>
        <Top>
        <Container>
        
            <ListContainer>
                <ListTitle>Export types</ListTitle>
                <Select onChange={changed} value={value}>
                {exportTypesArray.map(function(val, index){
                    //console.log("valindex",val,index);
                     return  <option key={"dropdown"+index} value={val.key}>{val.value}</option>
                })} 
                </Select>
            </ListContainer>
            {eventEnabled &&
            <ListContainer>
                <ListTitle>Select Event</ListTitle>
                <Select onChange={changedEvent} value={eventValue}>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
            </ListContainer>
            }
            <ListContainer>
                {exported && <p>Exported</p>

                }
                
                {!exporting &&
                <FormSubmit onClick={submit}>Export</FormSubmit>
                }
                
                {exporting && " Exporting, please wait"

                }
                </ListContainer>
            
        
            </Container>
            </Top>
    </Layout>
  );
}